import * as React from "react";

import { NavigationBar } from "@shared-ui/retail-product-navigation-bar";
import { PropertyStickyBookBar as SharedUiPropertyStickyBookBar } from "@shared-ui/lodging-property-offers";

import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkLayoutPosition } from "@egds/react-core/layout-position";

import { PropertyBexApiWrapper } from "components/shared/BexApiWrapper/PropertyBexApiWrapper";

// Custom sticky component while it's being promoted to uitk
import { StackedSticky } from "components/shared/StackedSticky/StackedSticky";
import { PropertyNavigationBarProps } from "./typings";
import { IdentifierType } from "@shared-ui/retail-product-relevant-content";

const MARKETING_PARAMS = ["semcid", "emlcid", "mdpcid"];

export const WrappedStickyBookBar = PropertyBexApiWrapper(SharedUiPropertyStickyBookBar);

export const PropertyNavigationBar = (props: PropertyNavigationBarProps) => {
  const { context, templateComponent } = props;
  /* istanbul ignore if */
  if (!templateComponent) {
    return null;
  }

  const { hideStickyBookBar, disableStickiness } = templateComponent.config;
  // Do not include the sticky bar for the marketing channels of if hideStickyBookBar attribute is set true in template
  const queryParams = context?.queryParams || {};
  const isMktRequest =
    Object.keys(queryParams)
      .map((key) => key.toLocaleLowerCase())
      .filter((key) => MARKETING_PARAMS.includes(key)).length > 0;

  const inputs = {
    productIdentifier: {
      id: `${context.searchContext.location.id}`,
      type: IdentifierType.PROPERTY_ID,
      travelSearchCriteria: {
        property: {
          primary: {
            dateRange: null,
            rooms: [{ adults: 2 }],
            destination: {
              regionId: String(context?.searchContext?.location?.parent?.id),
            },
          },
          secondary: {},
        },
      },
    },
  };

  const navigationBar = (
    <UitkSpacing border={["blockend"]}>
      <div>
        <NavigationBar inputs={inputs} />
      </div>
    </UitkSpacing>
  );

  return (
    <UitkLayoutPosition tag="section" type="relative">
      {!disableStickiness ? <StackedSticky>{navigationBar}</StackedSticky> : <>{navigationBar}</>}
      {!isMktRequest && !hideStickyBookBar && (
        <UitkLayoutPosition type="absolute" position={{ right: "three", top: "one" }}>
          <WrappedStickyBookBar context={context} clickTargetRef="startDate" />
        </UitkLayoutPosition>
      )}
    </UitkLayoutPosition>
  );
};

export default PropertyNavigationBar;
